import React, { useEffect, useState, startTransition } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import './Styles.css';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const imagen1 = require('../../../Global/Images/Enlaces/cambio 01.jpg');
const imagen2 = require('../../../Global/Images/Enlaces/cambio 02.jpg');
const imagen3 = require('../../../Global/Images/Enlaces/cambio 03.jpg');
const imagen4 = require('../../../Global/Images/Enlaces/Desc.jpg');
const imagen5 = require('../../../Global/Images/Enlaces/NEW 03.jpg');

interface PublicidadProps {
  imageIndex: number;
}

const Publicidad1: React.FC<PublicidadProps> = ({ imageIndex }) => {
  const imagenes = [imagen1, imagen2, imagen3, imagen4, imagen5];
  const selectedImage = imagenes[imageIndex] || imagen1;
  const [confettiVisible, setConfettiVisible] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    startTransition(() => {
      navigate('/main'); // Redirige a la página principal
    });
  };

  useEffect(() => {
    setConfettiVisible(true);
    const timer = setTimeout(() => setConfettiVisible(false), 3000); // El confeti se muestra durante 3 segundos
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='containerPublicty' style={{ position: 'relative' }}>
      {confettiVisible && (
        <ConfettiExplosion
          force={0.8}
          duration={3000}
          particleCount={250}
          width={1600}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
          }}
        />
      )}

      <div className="container">
        <div className="ribbon">
          <Typography className='titleRibbon' variant='h3' fontWeight={700} color={'#fff'}>
            Buró de vales
          </Typography>
          <Typography className='subtitleRibbon' variant='h6' fontWeight={700} color={'#fff'}>
            Adquiere nuestra app en Google Play o la AppStore
          </Typography>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img 
          src={selectedImage} 
          style={{ width: '50rem', height: '50rem', margin: '5vh', boxShadow: '0px 2px 45px rgba(0,0,0,0.55)', cursor: 'pointer' }} 
          alt="Publicidad" 
          className='imagePublitity' 
          onClick={handleClick} // Agrega el manejador de clic aquí
        />
      </div>
    </div>
  );
};

export default Publicidad1;
